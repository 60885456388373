<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
// 审核
export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
    };
  },
  async mounted() {
    await this.getConfigList('need_goods_detail_list');
  },

  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'branchCodeList') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/mdmOrgController/findOrgList';
        rowData.optionsKey = {
          label: 'orgName',
          value: 'orgCode',
        };
        rowData.multiple = true;
        // rowData.isSearch = true;
        rowData.paramsName = 'orgName';
        rowData.paramsFixed = {
          orgType: 'area',
        };
      }
      if (rowData.field === 'approveStatusList') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/mdmdictdata/list';
        rowData.optionsKey = {
          label: 'dictValue',
          value: 'dictCode',
        };
        rowData.multiple = true;
        // rowData.isSearch = true;
        rowData.paramsName = 'dictValue';
        rowData.paramsFixed = {
          dictTypeCode: 'dms_approval_state',
        };
      }
      if (rowData.field === 'orderTypeList') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/mdmdictdata/list';
        rowData.optionsKey = {
          label: 'dictValue',
          value: 'dictCode',
        };
        rowData.multiple = true;
        // rowData.isSearch = true;
        rowData.paramsName = 'dictValue';
        rowData.paramsFixed = {
          dictTypeCode: 'dms_order_type',
        };
      }
      if (rowData.field === 'applyStatusList') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/mdmdictdata/list';
        rowData.optionsKey = {
          label: 'dictValue',
          value: 'dictCode',
        };
        rowData.multiple = true;
        // rowData.isSearch = true;
        rowData.paramsName = 'dictValue';
        rowData.paramsFixed = {
          dictTypeCode: 'apply_status',
        };
      }
      return rowData;
    },
  },
};
</script>
